/* App.css */


body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}
.home-center{
  text-align: center;
}
.headers {
  padding-top: 100px;
}
.home-h1{
margin-bottom: 3%;
}
.service-boxes {
  display: flex;
  justify-content: space-around;
  margin-top: 20px; /* Adjust the margin as needed */
  flex-wrap: wrap;
}

.service-box {
  width: calc(50% - 190px); /* 50% width minus some margin */
  margin: 10px; /* Adjust the margin as needed */
  padding: 15px;
  text-align: center;
  background-color: #242526; /* Darker shade of purple */
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(10, 100, 100, 1);
}

.service-box:hover {
  transform: scale(1.05);

}
.link-style {
  /* Apply styles to the Link component */
  text-decoration: none; /* Remove default underline */
  color: inherit; /* Inherit color from parent */
}

.about {
  max-width: 700px; /* Set a maximum width for the container */
  margin: 0 auto; /* Center the container horizontally */
  text-align: center; /* Center the text within the container */
  padding: 30px; 
  line-height: 1.5;
}


.toolbar {
  background-color: #242526; /* Darker shade of purple */
  color: #fff;
  padding: 1px 2px; /* Increase top and bottom padding, adjust left and right padding */
  text-align: center;
  display: flex;
  justify-content: space-between; /* Spread logo and links evenly */
  align-items: center; /* Center items vertically */
    position: fixed;
    top: 0px;

}

.toolbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px; /* Adjust the padding as needed */
  white-space: nowrap;
}



.logo {
  background-color: #242526; /* Darker shade of purple for testing */
  padding: 15px 2px; 
  display: flex;
  align-items: center
}

.use-case {
  height: 250px;
  max-width: 20%;
  padding-right: 20px;
  margin-right: 20px; /* Adjust margin-right as needed */

  /* Clear the margin for the last element in a row */
  margin-bottom: 20px; /* Adjust margin-bottom as needed */
  margin-right: 0;

  /* Set box-sizing to border-box for more predictable sizing */
  box-sizing: border-box;
}




/* Media query for screens 900px and larger */
@media screen and (min-width: 600px) {
  .use-case {
    width: 48%; /* Two pictures side by side, with a small gap in between */
    margin-right: 2%; /* Adjust the gap between pictures */
  }
}


.logo img {
  height: 90px;
  width: auto;
  margin-right: 10px; 

}

.homepage-box-pic img {
  height: 80px;
  width: auto;
  margin-right: 10px; 

}


.nav-links {
  display: flex;
}

.toolbar a {
  color: #fff;
  text-decoration: none;
  margin: 0 15px; /* Increase left and right margin for spacing between links */
  padding: 10px; /* Add padding to make the links more clickable */
  border-radius: 5px; /* Add rounded corners for a modern look */
  justify-content: center;

}

.toolbar a:hover {
  background-color: #fff; /* Change background color on hover */
  color: #242526; /* Change text color on hover */
  text-decoration: none;
}

.page-content {
  padding: 20px;
  background-color: #242526; /* Use the same background color as the toolbar */
  color: #fff;
  min-height: 100vh;
}

.button-9 {
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display:block;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin:0 auto;
  outline: none;
  margin-top: 100px;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 20%;

}

.button-9:disabled {
  cursor: default;
}

.button-9:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

.padder {
  margin-bottom: 30%;
}

@media screen and (max-width: 599px) {
  .toolbar {
    flex-direction: column; /* Stack items vertically */
    text-align: center;
    padding: 10px;
  }

  

  .logo {
    padding: 15px;
    height: 10px;
    width: 10px;   
    margin-bottom: 10px; /* Add margin below the logo */
                  /* Adjust padding for smaller screens */
  }
 
  .button-9{
    width: fit-content;
  }
  .service-box{
    width: fit-content;
  }
  .use-case{
    max-width: 100%;;
  }

}